<template>
  <div>
    <bannerCommon></bannerCommon>
  </div>
</template>

<script>
import bannerCommon from './common/bannerCommon'
export default {
  components: {
    bannerCommon,
  },
  data() {
    return {}
  },
  created() {},
  methods: {},
}
</script>

<style scoped></style>
